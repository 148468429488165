<template>
  <div class="privacypolicy">
    <h2>Privacy Policy</h2>

    <br />

    <div class="container_pp">
      <!-- Objective Section -->

      <strong class="main-heading">1. Objective</strong>

      <p>
        Your privacy is very important to us and we are committed to protecting and respecting it, MITHAQ MEDIA
        PRODUCTION LIMITED ( “we”, “us” ) values the trust placed in us by data subject (“you”, “your”, “user”,
        “subscriber”) and therefore, we follow the highest standards of privacy guidelines to protect the information
        shared by you with us.
      </p>

      <p>
        This Privacy Policy (“Privacy Policy”) governs the use of Personal Information shared (as defined below) with or
        collected by Company from the users or subscribers of our online services in your country including the
        ‘noorplay’ service (“NoorPlay”) which has both free and premium content and services.
      </p>

      <p>
        This privacy policy describes the usage of information provided or collected on the sites and applications where
        this privacy policy is posted. We follow this privacy policy in accordance with applicable law in the places
        where we operate. In some cases, we may provide additional data privacy notices specific to certain services or
        regions. Those terms are to be read in combination with this policy.
      </p>

      <p>
        Please keep in mind that when you provide information to us on a third-party site or platform (for example, via
        our applications like social media login the information we collect is through those third-party sites linked
        with our applications is covered by this privacy policy, and the information the third-party site or platform
        collects is subject to the third-party site or platform’s privacy practices. Privacy choices you have made on
        the third-party site or platform will not apply to our use of the information we have collected directly through
        our applications.
      </p>

      <p>
        This Privacy Policy should be read in conjunction and together with the Terms of Use applicable to NoorPlay you
        are using.
      </p>

      <!-- User Consent Section  -->
      <strong class="main-heading">2. User Consent</strong>

      <p>
        By using NoorPlay (for example, when you register for an account, purchase a service, enter a contest or
        promotions, communicate with us, on our sites or applications), you agree to provide consent to our collection,
        use, and sharing of your personal information as described in this policy. By using NoorPlay you also consent to
        sharing of your Personal Information with third parties for marketing and advertising purposes. In case of any
        roaming user profile or if you are a traveler visiting various countries around the world and avail NoorPlay,
        then we will consider the country of registration as your primary country and the consents obtained at the time
        of registration will be valid for your processing of personal information.
      </p>

      <!-- Collection and Use of Personal Information section  -->
      <strong class="main-heading">3. Collection and Use of Personal Information</strong>

      <p>
        Personal Information or ‘PII’ is defined as any information that identifies (whether directly or indirectly) to
        a particular individual or natural person, such as the individual’s name, email address, and mobile number
        indicating to that particular person or is identifiable. When anonymous information is directly or indirectly
        associated with personal information, the resulting information also is treated as personal information.
      </p>

      <ol>
        <li>
          <strong class="sub-heading"> A. Information you give us: </strong>

          <p>
            You may give us information about you by filling in forms on the Service, by using the Service, or by
            corresponding with us via our related social media pages (including but not limited to: Facebook, Instagram,
            and/or Twitter, or by phone, e-mail or otherwise.) This includes information you provide when you register
            to use and/or subscribe to the Service, use the Service, participate in discussion boards or other social
            media functions on or related to the Service, enter a Competition, promotion, or survey, and when you report
            a problem with the Service. The information you give us may include your name, e-mail address and phone
            number, and/or other account information.
          </p>
        </li>

        <li>
          <strong class="sub-heading">B. Information we collect automatically:</strong>

          <p>
            With regard to each of your visits to, and your use of, the Service we may automatically collect the
            following information or statistical data:
          </p>

          <ol>
            <li>
              1. Activities on NoorPlay such as type of content, watch history, search queries, etc.
            </li>

            <li>
              2. Device information like device id, device model etc. and other unique identifiers.
            </li>

            <li>
              3. Your interactions with our email, customer care, and messaging platform.
            </li>

            <li>
              4. Geo-location information through IP addresses.
            </li>

            <li>
              5. Information collected via the use of cookies and other technologies.
            </li>
          </ol>

          <p>
            <span class="note">*Note:</span> The IP address that we collect automatically is used for
            transaction/billing purpose for the user willing to pay for using NoorPlay. Basis the IP address we also
            capture the geolocation from where the user is trying to access the content. This information is further
            used internally for taxation purpose to identify from which geolocation (country) the user has have availed
            the services.
          </p>
        </li>

        <li>
          <strong class="sub-heading">C. Inferred Information through usage and log data: </strong>
          We may track certain information about you based upon your behavior on NoorPlay. We use this information for
          conducting internal research on our users' demographics, devices, interests, and behavior to better
          understand, protect and serve our users. This information is compiled and analyzed on an aggregated basis. We
          may also collect Your Information to track user behavior and preferences for internal analytics and research.
          We may also use your information:

          <ol>
            <li>
              To evaluate Your interest for various genres of content, services, offers;
            </li>

            <li>
              To perform analytics and conduct customer research, to determine your interest, for identifying Content
              that generates sales, and to analyze traffic patterns.
            </li>
          </ol>
        </li>

        <li>
          <strong>D. Information we receive from other sources</strong>

          <ol>
            <li>
              We are working closely with third parties (including, for example, telecommunication operators and/or
              other business partners, sub-contractors in technical, advertising networks, analytics providers and
              search information providers) and may receive information about you from them.
            </li>

            <li>
              Billing Information: Based on Your purchase of a subscription package, our payment gateway partners
              including payment via ( Apple In-App Purchase, Google In-App Purchase, PayPal ..etc ) may collect
              information with respect to your credit/debit card details or other bank account details along with your
              billing address. This information is stored by our payment gateway partners. For instance, NoorPlay will
              be using third-party payment gateway partners to process and facilitate the payment of your subscription
              fee to NoorPlay for providing you access to the subscription. Please note that NoorPlay does not directly
              collect any financial information such as credit card or debit card or net banking details from you. Such
              payment gateway partners are not controlled by us. We encourage you to familiarize yourself with the
              privacy statements provided by such payment gateways.
            </li>

            <li>
              Auto-renewal Subscription: When you chose any subscription or paid service, we or our payment gateway
              provider may collect your purchase, address or billing information, including your credit card number and
              expiration date etc. However when you order using an in-app purchase option, same are handled by such
              platform providers. The subscriptions or paid NoorPlay may be on auto renewal mode unless cancelled. If at
              any point you do not wish to auto-renew your subscription, you may cancel your subscription before the end
              of the subscription term.
            </li>
          </ol>
        </li>
      </ol>

      <!-- <p>
        Noorplay uses <a href="https://vcms.mobiotics.com">vsms.mobiotics.com</a> cloud subscription service by
        Mobiotics IT Solution Private Limited ( <a href="https://www.mobiotics.com/">Mobiotics</a> ) to manage its
        subscribers and subscriptions. Such data is only used for managing subscription related functionality and is
        secured in Noorplay database. Noorplay might use such third party services to support different functionality
        ensuring data security.
      </p> -->

      <!-- COOKIES section -->
      <strong class="main-heading">4. COOKIES</strong>

      <p>
        We use cookies to distinguish you from other users of the Service. This helps us to provide you with a good
        experience when you browse the Service and also allows us to make improvements. A cookie is a very small text
        document, which often contains an anonymous unique identifier. Cookies are stored on your hard drive, not on the
        Service, and as such you are able to set your browser to decline cookies and/or delete them. If you do so, you
        will still be able to access the Service, but you may find that some areas or pages do not function as smoothly
        as they might with cookies enabled.
      </p>

      <p>
        We will use the following cookies:
      </p>

      <ol>
        <li>
          <p>
            <span class="note">Strictly necessary cookies:</span> These are cookies that are required for the operation
            of the Service. They include, for example, cookies that enable you to log into secure areas.
          </p>

          <p>
            <span class="note">Analytical/performance cookies:</span> These allow us to recognize and count the number
            of visitors to the Service, and to see how they move around the Service when they are using it. This helps
            us to improve the way the Service works.
          </p>

          <p>
            <span class="note">Functionality cookies:</span> These are used to recognize you when you return to the
            Service.
          </p>

          <p>
            <span class="note">Targeting cookies:</span>
            These cookies record your visit to the Service, the pages you have visited, the content pages and content
            you have viewed, and the links you have followed.
          </p>
        </li>
      </ol>

      <strong class="main-heading">5. Disclosure to third parties</strong>

      <p>
        <span class="note">General Disclosure</span> At times the Company may make certain personal information
        available to strategic partners that work with the Company to provide service, or that help the Company market
        to customers. For example, when you purchase and activate subscription, you authorize the Company to exchange
        the information you provide during the activation process to carry out a service. If you are approved for
        service, your account will be governed by the Company’s privacy policy. Personal information will only be shared
        by the Company to provide or improve our service and marketing aspects; it will not be shared with third parties
        for their marketing purposes. We do not share your personal information with companies, organizations, or
        individuals outside of Company except in the following cases:
      </p>

      <ol>
        <li>
          <span class="note">Service Providers</span>
          <p>
            NoorPlay may provide links to various third-party websites that may collect Your Personal Information. Use
            of Your information collected by such third-party platform will be governed by the privacy policy in such
            third-party platform. Company has no control on the operation of such third-party websites and you may share
            information with such third party at your sole risk. Comapny shall not be liable to you for any
            misappropriation of Your Personal Information by such third party.
          </p>

          <p>
            We may share non-personally identifiable information publicly and with our partners - like publishers,
            advertisers, developers, or rights holders. For example, we share information publicly to show trends about
            the general use of our services. We also allow specific partners to collect information from your browser or
            device for marketing/promotions and measurement purposes using their own cookies or similar technologies.
          </p>
        </li>

        <li>
          <span class="note">Promotional Offers</span>

          <p>
            We will share or disclose your personal information outside of the Company. For example, providing e-mail
            related services such as marketing newsletters, promotions and offers.
          </p>
        </li>

        <li>
          <span class="note">Business Transfers</span>
          <p>
            We provide personal information to our affiliates and other trusted businesses or persons to process it for
            us, based on our instructions and in compliance with our Privacy Policy and any other appropriate
            confidentiality and security measures. For example, we use service providers to help us with customer
            support.
          </p>
        </li>

        <li>
          <span class="note">Legal</span>
          <p>
            In the event of any requirement by court order, government, or quasi-government agency to disclose Your
            Personal Information, We will disclose information as may be legally required. We may also disclose Your
            Personal Information if we, in the good faith belief that such disclosure is reasonably necessary to respond
            to subpoenas, court orders, or other legal processes.
          </p>

          <p>
            In the event Company is merged with or acquired by another company, we and our affiliates may share your
            personal information, wholly or in part, with another business entity.
          </p>
        </li>
      </ol>

      <!--  STORAGE AND SECURITY section -->
      <strong class="main-heading">6. STORAGE AND SECURITY</strong>

      <p>
        The Service is secured and encrypted for your privacy. However, the data that we collect from you may be
        transferred to, and stored at, a destination outside of the MENA Region. It may also be processed by staff
        operating outside the MENA Region who work for us or for one of our suppliers. By submitting your personal data,
        you agree to this transfer, storing or processing. We will take all steps reasonably necessary to ensure that
        your data is treated securely and in accordance with this privacy policy. Credit and/or debit card details will
        not be stored, sold, shared, rented, or leased to any third party. Once we have received your information, we
        will use strict procedures and security features to try to prevent unauthorized access.
      </p>

      <p>
        We are continuously implementing and updating administrative, technical, and physical security measures to help
        protect your information against unauthorized access, loss, destruction, or alteration. Some of the safeguards
        we use to protect your information are firewalls and data encryption, and information access controls.
      </p>

      <p>
        We operate globally and may transfer your personal information to individual companies of the Company affiliated
        companies or third parties in locations around the world for the purposes described in this privacy policy.
        Wherever your personal information is transferred, stored or processed by us, we will take reasonable steps to
        safeguard the privacy of your personal information.
      </p>

      <!-- Managing Your Information section -->
      <strong class="main-heading">7. Managing Your Information</strong>

      <p>
        A. You can access and update some of your personal information through your account settings. If you have chosen
        to connect your Company account to a third-party application, like Facebook, Google or Twitter, you can change
        your settings and remove permission for the app by changing your account settings. You are responsible for
        keeping your personal information up-to-date.
      </p>

      <p>
        B. You can ask us to correct inaccurate or incomplete personal information concerning you (this is the
        information which you cannot update yourself within your Comapny account) by sending us an e-mail.
      </p>

      <p>
        C. We retain your personal information as long as necessary for the performance of the contract between you and
        us to comply with our legal obligations. If you no longer want us to use your information then you can request
        that we erase your personal information and close your Comapny account By contacting us At support@noorplay.com
      </p>

      <p>
        D. For withdrawing your consent at any time during the tenure of your services with us, you may choose to do so
        by sending us an e-mail. We shall review your request and may ask you to verify your identity. Post verification
        we will withdraw the consent for which request was made by you and stop any further processing of your personal
        information.
      </p>

      <p>
        E. We will keep your data only for as long as is necessary to respond to any queries or complaints, to improve
        the Services that we offer to you, or to comply with any legal obligations to which we may be subject. We may
        retain personal data after our contractual relationship with you ends to deal with any post-contractual issues
        (in accordance with applicable statute of limitations) or follow-up queries from you.
      </p>

      <!--Children’s Privacy section-->
      <strong class="main-heading">8. Children’s Privacy</strong>

      <p>
        The company is not intentionally designed for or directed at persons less than 18 years of age. The company does
        not knowingly permit any person who is under 18 years of age to register with the services or to provide any
        other personally-identifying information. However, we consider it the responsibility of parents to monitor their
        children’s use of our services. Nevertheless, it is our policy not to collect and process any personal
        information from children below 18 years of age or offer to send any promotional materials to persons in that
        category. The company does not seek or intend to seek or receive any personal information from children.
      </p>

      <!-- Respecting your contact preferences section -->
      <strong class="main-heading">9. Respecting your contact preferences</strong>

      <p>
        The company is committed to complying with the Do Not Call (DNC) provisions. We have always been mindful of
        engaging our customers in a more targeted and relevant way.
      </p>

      <p>
        Our marketing messages aim to update you about our exclusive offers, rewards program, and special deals from our
        preferred partners and advertisers.
      </p>

      <p>
        If you wish to continue receiving such messages or to opt-out, you can indicate your preference at any time via
        sending an email to our customer support team.
      </p>

      <p>
        Please note that after opting out, you may still continue to receive non-marketing messages, such as product
        updates and service notices as permitted under applicable local laws and regulations.
      </p>

      <!-- Change in Policy section -->
      <strong class="main-heading">10. Change in Policy</strong>

      <p>
        This Privacy Policy is subject to change from time to time. We reserve the right at our sole discretion to
        modify the terms of this Privacy Policy from time to time in order to ensure compliance with applicable laws.
      </p>

      <p>
        The Updated Terms shall be effective immediately and shall supersede these the terms of this Privacy Policy.
      </p>

      <p>
        We will not be under an obligation to notify you of any changes to this privacy policy. You shall be solely
        responsible for reviewing the Privacy Policy from time to time for any modifications.
      </p>

      <p>
        By continuing to use Company after the updated Terms have been published, you affirm your agreement to the
        updated terms.
      </p>

      <strong class="main-heading">11. How To Contact US</strong>

      <p>
        If you have any further queries please contact us on the below email address:
        <span class="note">support@noorplay.com</span>
      </p>

      <br />

      <p class="note center">
        This Privacy Policy Revised / Updated On 28 Septemper 2021 And Effective As Of The Date Posted.
      </p>
    </div>

    <!-- <div class="container_pp">
      <p>
        This Privacy Statement explains our practices, including your choices, regarding the collection, use, and
        disclosure of certain information, including your personal information, will be processed by us.
      </p>
      <p>
        By visiting www.noorplay.com (the “Service”) you are accepting and consenting to the practices described in this
        policy
      </p>
      <p>
        <strong>INFORMATION WE MAY COLLECT FROM YOU</strong>
      </p>
      <p>
        We may receive and process the following information about you:
      </p>

      <ol>
        <li class="list">
          <strong>Information we receive from you:</strong>
          We collect information you provide to us which includes:
          <ol>
            <li>
              your name surname, age, email address, address or postal code, payment method(s), and telephone number. In
              certain countries, we collect a government identification number for purposes such as billing and tax
              compliance. We collect this information in a number of ways, including when you enter it while using our
              service, interact with our customer service, or participate in surveys or marketing promotions;
            </li>
            <li>
              information when you choose to provide ratings, taste preferences, account settings (including preferences
              set in the "Account" section of our website), or otherwise provide information to us through our service
              or elsewhere.
            </li>
          </ol>
        </li>
      </ol>

      <ol>
        <li>
          <strong>Information we collect automatically:</strong>
          We collect information about you and your use of our service, your interactions with us and our advertising,
          as well as information regarding your computer or other device(s) used to access our service (such as gaming
          systems, smart TVs, mobile devices, set top boxes, and other streaming media devices). This information
          includes:
          <ol>
            <li>your activity on the Noorplay service, such as title selections, watch history and search queries;</li>
            <li>
              your interactions with our emails and texts, and with our messages through push and online messaging
              channels;
            </li>
            <li>
              details of your interactions with our customer service, such as the date, time and reason for contacting
              us, transcripts of any chat conversations, and if you call us, your phone number and call recordings;
            </li>
            <li>device IDs or other unique identifiers;</li>
            <li>
              resettable device identifiers (also known as advertising identifiers), such as those on mobile devices,
              tablets, and streaming media devices that include such identifiers
            </li>
            <li>
              device and software characteristics (such as type and configuration), connection information, statistics
              on page views, referring source (for example, referral URLs), IP address (which may tell us your general
              location), browser and standard web server log information;
            </li>
            <li>
              information collected via the use of cookies, web beacons and other technologies, including ad data (such
              as information on the availability and delivery of ads, the site URL, as well as the date and time). (See
              our "Cookies and Internet Advertising" section for more details.)
            </li>
          </ol>
        </li>
      </ol>

      <ol>
        <li>
          <strong>Information from partners:</strong>
          We collect information from other companies with whom you have a relationship (“Partners”). These Partners
          might include (depending on what services you use): your TV or Internet service provider, or other streaming
          media device providers who make our service available on their device; mobile phone carriers or other
          companies who provide services to you and collect payment for the service for distribution to us; and voice
          assistant platform providers who enable interaction with our service via voice commands. The information
          Partners provide us varies depending on the nature of the Partner services, and may include:
          <ol>
            <li>
              media related search queries and commands you make through Partner devices or voice assistant platforms;
            </li>
            <li>service activation information such as your email address or other contact information;</li>
            <li>
              device IDs or other unique identifiers that support user authentication, the service registration
              experience, Partner payment processing, and the presentation of Noorplay content to you through portions
              of the Partner user interface
            </li>
          </ol>
        </li>
      </ol>

      <ol>
        <li>
          <strong>Information from other sources:</strong>
          We also obtain information from other sources. We protect this information according to the practices
          described in this Privacy Statement, plus any additional restrictions imposed by the source of the data. These
          sources vary over time, but could include:
          <ol>
            <li>
              service providers that help us determine a location based on your IP address in order to customize our
              service and for other uses consistent with this Privacy Statement;
            </li>
            <li>
              payment service providers who provide us with payment information, or updates to that information, based
              on their relationship with you;
            </li>
            <li>
              online and offline data providers, from which we obtain demographic, interest based and online advertising
              related data;
            </li>
            <li>publicly-available sources such as open government databases</li>
          </ol>
        </li>
      </ol>

      <p>
        <strong>USE OF INFORMATION</strong>
      </p>

      <p>
        We use data to provide, analyze, administer, enhance and personalize our services and marketing efforts, to
        process your registration, your orders and your payments, and to communicate with you on these and other topics.
        For example, we use information to
      </p>

      <ol>
        <li>
          determine your general geographic location, provide localized content, provide you with customized and
          personalized viewing recommendations for movies and TV shows we think will be of interest to you, determine
          your Internet service provider, and help us quickly and efficiently respond to inquiries and requests;
        </li>
        <li>
          to coordinate with Partners on making the service available to members and providing information to
          non-members about the availability of the service, based on the specific relationship you have with the
          Partner;
        </li>
        <li>
          prevent, detect and investigate potentially prohibited or illegal activities, including fraud, and to enforce
          our terms (such as determining free trial eligibility);
        </li>
        <li>
          analyze and understanding our audience, improve our service (including our user interface experiences) and
          optimize content selection, recommendation algorithms and delivery;
        </li>
        <li>
          communicate with you concerning our service so that we can send you news about Noorplay, details about new
          features and content available on Noorplay, special offers, promotional announcements, consumer surveys, and
          to assist you with operational requests such as password reset requests. These communications may be by
          various methods, such as email, push notifications, text message, online messaging channels.
        </li>
      </ol>

      <p>
        DISCLOSURE OF YOUR INFORMATION
      </p>

      <p>We may share your information with selected third parties including:</p>

      <ol>
        <li>
          business partners, suppliers and sub-contractors for the performance of any contract we enter into with them
          or you;
        </li>
        <li>
          advertisers and advertising networks that require the data to select and serve relevant adverts to you and
          others; and/or
        </li>
        <li>
          analytics and search engine providers that assist us in the improvement and optimisation of the Service.
        </li>
      </ol>

      <p>We may disclose your personal information to third parties:</p>

      <ol>
        <li>
          in the event that we sell or buy any business or assets, in which case we may disclose your personal data to
          the prospective seller or buyer of such business or assets;
        </li>
        <li>
          in which case personal data held by it about its customers will be one of the transferred assets; and/or
        </li>
        <li>
          If we are under a duty to disclose or share your personal data in order to comply with any legal obligation,
          or in order to enforce or apply our terms of use and/or other agreements; or to protect the rights, property,
          or safety of Noorplay, our customers, or others.
        </li>
      </ol>

      <p>
        Noorplay uses <a href="https://vcms.mobiotics.com">vsms.mobiotics.com</a> cloud subscription service by
        Mobiotics IT Solution Private Limited ( <a href="https://www.mobiotics.com/">Mobiotics</a> ) to manage its
        subscribers and subscriptions. Such data is only used for managing subscription related functionality and is
        secured in Noorplay database. Noorplay might use such third party services to support different functionality
        ensuring data security.
      </p>

      <p>
        <strong>COOKIES</strong>
      </p>

      <p>
        We use cookies to distinguish you from other users of the Service. This helps us to provide you with a good
        experience when you browse the Service and also allows us to make improvements. A cookie is a very small text
        document, which often contains an anonymous unique identifier.
      </p>

      <p>
        Cookies are stored on your hard drive, not on the Service, and as such you are able to set your browser to
        decline cookies and/or delete them. If you do so, you will still be able to access the Service, but you may find
        that some areas or pages do not function as smoothly as they might with cookies enabled.
      </p>

      <p>We will use the following cookies:</p>

      <ol>
        <li>
          Strictly necessary cookies. These are cookies that are required for the operation of the Service. They
          include, for example, cookies that enable you to log into secure areas;
        </li>
        <li>
          Analytical/performance cookies. These allow us to recognize and count the number of visitors to the Service,
          and to see how they move around the Service when they are using it. This helps us to improve the way the
          Service works;
        </li>
        <li>Functionality cookies. These are used to recognize you when you return to the Service; and</li>
        <li>
          Targeting cookies. These cookies record your visit to the Service, the pages you have visited, the Content
          Pages and Content you have viewed, and the links you have followed. We will use this information to make the
          Service and, if applicable, any advertising displayed on it, more relevant to your interests. We may also
          share this information with third parties for this purpose
        </li>
      </ol>

      <p>
        <strong>STORAGE AND SECURITY</strong>
      </p>

      <p>
        The Service is secured and encrypted for your privacy. However, the data that we collect from you may be
        transferred to, and stored at, a destination outside of the Region. It may also be processed by staff operating
        outside the Region who work for us or for one of our suppliers. By submitting your personal data, you agree to
        this transfer, storing or processing. We will take all steps reasonably necessary to ensure that your data is
        treated securely and in accordance with this privacy policy. Credit and/or debit card details will not be
        stored, sold, shared, rented or leased to any third party.
      </p>

      <p>
        Unfortunately, the transmission of information via the Internet is not completely secure. Although we will do
        our best to protect your personal data, we cannot guarantee the security of your data transmitted to the
        Service, and as such any transmission is at your own risk. Once we have received your information, we will use
        strict procedures and security features to try to prevent unauthorized access.
      </p>

      <p>
        <strong>
          THIRD PARTY WEBSITES
        </strong>
      </p>

      <p>
        The Service may, from time to time, contain links to and from the websites of third parties, our partner
        networks, advertisers and/or affiliates. Links to or from external websites not owned or controlled by us do not
        constitute an endorsement by us of the products or information presented therein.
      </p>

      <p>
        If you follow a link to any of these external websites, please note that they will have their own privacy
        policies for which we do not accept any responsibility or liability. Please check those policies before you
        submit any personal data to those external websites.
      </p>

      <p><strong>CHANGES TO PRIVACY POLICY</strong></p>

      <p>
        Any changes we may make to our privacy policy in the future will be posted on this page. Please check back
        frequently to see any updates or changes to our privacy policy.
      </p>

      <p><strong>CONTACT</strong></p>

      <p>
        Questions, comments and requests regarding this privacy policy are welcomed and should be addressed to
        <a href="mailto:support@noorplay.com">support@noorplay.com</a>
      </p>
    </div> -->
  </div>
</template>

<script>
import { eventBus } from "@/eventBus";

export default {
  name: "privacyPolicy",
  data() {
    return {
      localeLanguage: null,
    };
  },
  watch: {
    localeLanguage(val) {
      if (val === "eng") {
        this.$router.push("/PrivacyPolicy");
      } else if (val === "ara") {
        this.$router.push("/PrivacyPolicy-ar");
      }
    },
  },
  created() {
    window.scrollTo(0, 0);

    //checking for change of language in local storage value
    eventBus.$on("tc-pp", (data) => {
      this.localeLanguage = data;
    });
  },
};
</script>

<style lang="scss" scoped>
.privacypolicy {
  background-color: #161616;
  padding: 30px;
  h2 {
    color: #fff;
    margin-top: 0;
    font-size: 2rem;
    font-family: Open Sans, sans-serif;
  }
  .container_pp {
    font-family: Open Sans, sans-serif;
    color: #fff;
    font-size: 1rem;
    letter-spacing: 0.15px;

    .note {
      font-weight: bold;
    }

    .center {
      text-align: center;
    }

    .main-heading {
      font-size: 1.25rem;
    }

    p {
      margin: 15px 0;
      line-height: 23px;
    }

    ol {
      padding: 10px 20px;
      list-style-type: none;
      line-height: 20px;
      li {
        padding: 10px 0;
        list-style-type: none;
        line-height: 20px;
      }
    }
  }
}
</style>