<template>
  <div class="privacypolicy_ar" dir="rtl">
    <h2>سياسة خاصة</h2>
    <br />
    <div class="container_pp">
      <!-- Objective Section -->

      <strong class="main-heading">1. الهدف</strong>

      <p>
        خصوصيتك مهمة جداً بالنسبة لنا, تقدر “ MITHAQ MEDIA PRODUCTION LIMITED “ (“نحن” أو “الشركة”) الثقة التي يوليها
        لنا صاحب البيانات (“أنت” أو “المستخدم” أو “المشترك”). ولذلك، نحن نتبع أعلى معايير الخصوصية لحماية المعلومات التي
        تدلي بها إلينا.
      </p>

      <p>
        تنظم سياسة الخصوصية الماثلة (“سياسة الخصوصية”) استخدام المعلومات الشخصية التي يتم الإدلاء بها (كما هو موضح
        أدناه) إلى الشركة أو تجمعها الشركة من المستخدمين أو المشتركين في الخدمات التي نقدمها عبر الإنترنت في بلدك بما في
        ذلك خدمة “noorplay” (“نوربلاي”) التي تقدم محتوى وخدمات مجانية وكذلك مقابل رسوم.
      </p>

      <p>
        تصف سياسة الخصوصية الماثلة استخدام المعلومات التي تٌقدم أو تجمع على المواقع والتطبيقات التي يتم نشر سياسة
        الخصوصية الماثلة فيها. ونحن نتبع سياسة الخصوصية الماثلة وفقًا للقانون المعمول به في الأماكن التي نعمل فيها. وفي
        بعض الحالات، قد ننشر إشعارات إضافية بشأن خصوصية البيانات فيما يخص خدمات أو مناطق معينة. ويجب قراءة هذه الشروط مع
        هذه السياسة.
      </p>

      <p>
        يرجى الانتباه إلى أنه عندما تقدم معلومات إلينا على موقع أو منصة تابعة لطرف آخر (على سبيل المثال من خلال
        تطبيقاتنا مثل تسجيل الدخول عبر وسائل التواصل الاجتماعي، فإن المعلومات التي نجمعها من خلال مواقع الأطراف الأخرى
        المرتبطة بتطبيقاتنا تخضع لسياسة الخصوصية الماثلة، والمعلومات التي يجمعها موقع أو منصة الطرف الآخر تخضع لممارسات
        الخصوصية لموقع أو منصة الطرف الآخر، ولن تنطبق خيارات الخصوصية التي حددتها على موقع أو منصة الطرف الآخر على
        استخدامنا للمعلومات التي جمعناها مباشرة من خلال تطبيقاتنا.
      </p>

      <p>
        يجب قراءة سياسة الخصوصية هذه مع شروط الاستخدام المعمول بها في خدمة نوربلاي " NoorPlay " التي تستخدمها.
      </p>

      <!-- User Consent Section  -->
      <strong class="main-heading">2. موافقة المستخدم</strong>

      <p>
        باستخدام نوربلاي (على سبيل المثال، عندما تقوم بالتسجيل للحصول على حساب أو شراء خدمة أو الدخول في مسابقة أو
        المشاركة في عروض ترويجية أو التواصل معنا على مواقعنا أو تطبيقاتنا)، فإنك توافق على جمعنا لمعلوماتك الشخصية
        واستخدامها وتبادلها على النحو المحدد في هذه السياسة. وباستخدام نوربلاي فإنك أيضًا توافق على تبادل معلوماتك
        الشخصية مع أطراف أخرى لأغراض التسويق والإعلان.
      </p>

      <p>
        في حالة وجود أي حساب مستخدم لمتجول أو إذا كنت مسافرًا يزور العديد من البلدان في جميع أنحاء العالم وتستفيد من
        خدمتنا, سنعتبر البلد الذي سجلت منه هو بلدك الأساسي وستسري الموافقات التي تم الحصول عليها في وقت التسجيل لمعالجة
        معلوماتك الشخصية.
      </p>

      <!-- Collection and Use of Personal Information section  -->
      <strong class="main-heading">3. جمع وإستخدام المعلومات الشخصية</strong>

      <p>
        يتم تعريف “المعلومات الشخصية” أو “معلومات التعريف الشخصية” على أنها أي معلومات تحدد (سواء بشكل مباشر أو غير
        مباشر) شخصًا معينًا أو شخصًا طبيعيًا، مثل اسم الفرد وعنوان بريده الإلكتروني ورقم هاتفه المتحرك بما يدل على هوية
        ذلك الشخص بعينه أو يمكن من تحديدها.
      </p>

      <ol>
        <li>
          <strong class="sub-heading">A. المعلومات التي تقدمها لنا </strong>

          <p>
            قد تقدم لنا معلومات خاصة بك من خلال قيامك بملء النماذج الموجودة على الخدمة الخاصة بنا أو من خلال استخدام
            الخدمة أو من خلال اتصالك بنا عبر صفحات التواصل الاجتماعي ذات الصلة ( بما في ذلك على سبيل المثال لا الحصر:
            فيس بوك والإنستجرام وتويتر أو عبر الهاتف أو البريد الإلكتروني أو أية وسيلة اتصال أخرى ) وتشتمل هذه المعلومات
            أية معلومات تقدمها عند التسجيل أو الاشتراك في خدمتنا، أو استخدامها أو من خلال مشاركتك في هيئات المناقشة، أو
            غيرها من خصائص المواقع الاجتماعية أو كل ما يتصل بالخدمة، أو من خلال مشاركتك في إحدى المسابقات، أو الحملات
            الترويجية، أو الاستقصاءات، وعند قيامك بإبلاغنا عن مشكلة ما مع الخدمة. قد تشتمل المعلومات التي تقدمها لنا على
            اسمك، عنوان بريدك الإلكتروني ورقم هاتفك، معلومات حسابك.
          </p>
        </li>

        <li>
          <strong class="sub-heading">B. المعلومات التي نجمعها تلقائيا </strong>

          <p>
            في كل مرة تقوم من خلالها بزيارة موقعنا واستخدامك الخدمة قد نقوم بجمع المعلومات التالية أو البيانات الإحصائية
            التالية بشكل آلي :
          </p>

          <ol>
            <li>
              1. الأنشطة على نوربلاي مثل نوع المحتوى وسجل المشاهدة وعمليات البحث وغيرها.
            </li>

            <li>
              2. معلومات الجهاز مثل معرف الجهاز وموديل الجهاز وغير ذلك من المعرفات الفريدة الأخرى.
            </li>

            <li>
              3. تفاعلاتك مع منصة بريدنا الإلكتروني وخدمة العملاء والرسائل.
            </li>

            <li>
              4. معلومات الموقع الجغرافي من خلال عناوين بروتوكول الإنترنت.
            </li>

            <li>
              5. المعلومات التي يتم جمعها عبر استخدام ملفات تعريف الارتباط والتقنيات الأخرى.
            </li>
          </ol>

          <p>
            * ملاحظة: يتم استخدام عنوان برتوكول الإنترنت الذي نحصل عليه تلقائيًا لغرض المعاملة / إصدار الفواتير للمستخدم
            الراغب في الدفع مقابل استخدام خدمة نوربلاي. وعلى أساس عنوان بروتوكول الإنترنت، نحصل أيضًا على الموقع
            الجغرافي الذي يحاول المستخدم منه الوصول إلى المحتوى. وتستخدم هذه المعلومات كذلك داخليًا لأغراض الضريبة
            لتحديد الموقع الجغرافي (البلد) الذي استخدم منه المستخدم الخدمات.
          </p>
        </li>

        <li>
          <strong class="sub-heading">C. المعلومات المستنتجة من خلال بيانات الاستخدام وتسجيل الدخول </strong>
          <p>
            قد نقوم بتتبع بعض المعلومات عنك بناءً على سلوكك على منصة نوربلاي. ونحن نستخدم هذه المعلومات لإجراء البحوث
            الداخلية حول التركيبة السكانية لمستخدمينا والأجهزة والاهتمامات والسلوك لفهم مستخدمينا وحمايتهم وخدمتهم بشكل
            أفضل. ويتم جمع هذه المعلومات وتحليلها على أساس عام وليس فردي. قد نقوم أيضًا بجمع معلوماتك لتتبع سلوك
            المستخدم وتفضيلاته لأغراض التحليلات والبحوث الداخلية. وقد نستخدم معلوماتك أيضًا:
          </p>

          <ol>
            <li>
              لتقييم اهتمامك بأنواع مختلفة من المحتوى والخدمات والعروض.
            </li>

            <li>
              لإجراء تحليلات وبحوث تخص العملاء لتحديد اهتمامك لتحديد المحتوى الذي يزيد من المبيعات وتحليل أنماط زيارة
              الموقع.
            </li>
          </ol>
        </li>

        <li>
          <strong>D. المعلومات التي نتلقاها من مصادر أخرى</strong>

          <ol>
            <li>
              نعمل عن كثب مع أطراف خارجية (بما فيها على سبيل المثال جهات تشغيل الاتصالات والشركاء الآخرين في الأعمال،
              أحدهما أو كليهما، والمقاولون من الباطن الذين يعملون في المجال التقني، وشبكات إعلانية، ومزودي خدمات
              تحليلية، ومزودي معلومات البحث) ويمكن أن نحصل على معلومات تتعلق بك من خلالهم.
            </li>

            <li>
              معلومات الفواتير : عند شرائك حزمة الاشتراك، يجوز لشركاء بوابة الدفع لدينا، بما في ذلك الدفع عبر ( Apple
              In-App Purchase, Google In-App Purchase, PayPal .. etc ) جمع المعلومات المتعلقة بتفاصيل بطاقة الائتمان /
              الخصم الخاصة بك أو تفاصيل الحساب المصرفي الأخرى إلى جانب عنوان استلام الفواتير الخاص بك. ويتم تخزين هذه
              المعلومات من خلال شركاء بوابة الدفع الخاصة بنا. فعلى سبيل المثال، ستستخدم نوربلاي شركاء بوابة الدفع الخاصة
              بأطراف أخرى لمعالجة وتسهيل دفع رسوم اشتراكك إلى نوربلاي لإتاحة الفرصة لك للاشتراك. يرجى ملاحظة أن نوربلاي
              لا تجمع بصورة مباشرة أي معلومات مالية مثل بطاقة الائتمان أو بطاقة الخصم أو التفاصيل المصرفية على الإنترنت
              منك. ولا يخضع شركاء بوابة الدفع لسيطرتنا. نشجعك على التعرف على بيانات الخصوصية التي توفرها بوابات الدفع
              تلك.
            </li>

            <li>
              تجديد الإشتراك التلقائي : عندما تختار أي اشتراك أو خدمة مدفوعة، يجوز لنا أو لمزود بوابة الدفع لدينا جمع
              معلومات الشراء أو العنوان أو الفواتير، بما في ذلك رقم بطاقتك الائتمانية وتاريخ انتهاء سريانها وما إلى ذلك.
              ومع ذلك، عند الطلب باستخدام خيار الشراء من خلال التطبيق، يتم التعامل مع الأمر من خلال مزودي المنصة. ويجوز
              وضع الاشتراكات أو خدمة نوربلاي المدفوعة في وضع التجديد التلقائي ما لم يتم إلغاؤها. وإذا كنت لا ترغب في أي
              وقت في تجديد اشتراكك تلقائيًا، فيمكنك إلغاء اشتراكك قبل نهاية مدة الاشتراك.
            </li>
          </ol>
        </li>
      </ol>

      <!-- COOKIES section -->
      <strong class="main-heading">4. ملفات تعريف الارتباط (الكوكيز)</strong>

      <p>
        نحن نستخدم ملفات تعريف الارتباط لنميز بينك وبين المستخدمين الآخرين للخدمة، الأمر الذي يساعدنا في أن نقدم لك
        تجربة جيدة عند تصفحك للخدمة، كما يتيح لنا إجراء التحسينات. ملف تعريف الارتباط عبارة عن وثيقة نص صغير جداً يحتوي
        في العادة على معرّف وحيد مجهول.
      </p>

      <p>
        تخزن ملفات تعريف الارتباط على القرص الصلب لديك، وليس على الخدمة، وبالتالي يمكنك أن تغير إعدادات محرك البحث بحيث
        ترفض حفظ ملفات تعريف الارتباط و/أو تحذفها. حتى وإن قمت بذلك، ستظل قادراً على الدخول إلى الخدمة، إلا أنك قد تجد
        بأن بعض المساحات أو الصفحات لا تعمل بالسلاسة نفسها في حال تفعيل ملفات تعريف الارتباط.
      </p>
      <p>
        نستخدم الأنواع التالية من ملفات تعريف الارتباط:
      </p>

      <ol>
        <li>
          <p>
            <span class="note">ملفات تعريف الارتباط الضرورية:</span> ملفات تعريف الارتباط هذه مطلوبة لتشغيل الخدمة، وهي
            تشتمل على سبيل المثال، ملفات تعريف الارتباط التي تمكنك من الدخول إلى المساحات الآمنة.
          </p>

          <p>
            <span class="note">ملفات تعريف الارتباط التحليلية/الأدائية:</span> تسمح لنا ملفات تعريف الارتباط
            التحليلية/الأدائية هذه بمعرفة الزائرين للخدمة ومعرفة أعدادهم لنعرف كيف يتصفحون الخدمة عند استخدامها. وهذه
            العملية تساعدنا في تحسين آلية عمل الخدمة.
          </p>

          <p>
            <span class="note">ملفات تعريف الارتباط الوظيفية:</span> تستخدم في التعرف عليك عندما تزور الخدمة من جديد.
          </p>

          <p>
            <span class="note">ملفات تعريف الارتباط الهادفة:</span>
            تسجل هذه الملفات زيارتك للخدمة، والصفحات التي قمت بزيارتها وصفحات المحتوى والمحتوى الذي قمت باستعراضه
            والروابط التي تبعتها.
          </p>
        </li>
      </ol>

      <strong class="main-heading">5. الإفصاح لأطراف أخرى</strong>

      <p>
        <span class="note">الإفصاح العام</span> يجوز للشركة في بعض الأحيان تقديم بعض المعلومات الشخصية المتوفرة لشركاء
        استراتيجيين يعملون مع الشركة لتقديم الخدمة أو يساعدون الشركة على التسويق للعملاء. وعلى سبيل المثال، عند شراء
        وتفعيل الاشتراك، أنت تفوض الشركة بتبادل المعلومات التي تقدمها أثناء عملية التنشيط لتنفيذ أي خدمة. وإذا تم قبولك
        في الخدمة، سوف تخضع لسياسة الخصوصية للشركة. ولن يتم تبادل المعلومات الشخصية من قبل الشركة ألا لتوفير أو تحسين
        خدماتنا أو جوانبنا التسويقية. ولن يتم تبادل المعلومات الشخصية مع أطراف أخرى لأغراض التسويق الخاصة بهم. لن نتبادل
        معلوماتك الشخصية مع شركات أو مؤسسات أو أفراد خارج الشركة باستثناء الحالات التالية:
      </p>

      <ol>
        <li>
          <span class="note"> مزودو الخدمة</span>
          <p>
            قد توفر نوربلاي روابط إلى مختلف مواقع الأطراف الأخرى التي قد تجمع معلوماتك الشخصية. ويخضع استخدام معلوماتك
            التي يتم جمعها بواسطة تلك المنصة التابعة لطرف آخر لسياسة الخصوصية على منصة ذلك الطرف الآخر. ولا تملك الشركة
            أي سيطرة على تشغيل تلك المواقع الخاصة بأطراف أخرى، ويمكنك تبادل المعلومات مع ذلك الطرف الآخر على مسؤوليتك
            وحدك. ولا تتحمل الشركة أي مسؤولية تجاهك عن أي اختلاس لمعلوماتك الشخصية من قبل ذلك الطرف الآخر.
          </p>

          <p>
            يجوز لنا تبادل المعلومات التي لا تكشف الهوية الشخصية بشكل علني ومع شركائنا مثل الناشرين أو المعلنين أو
            المطورين أو أصحاب الحقوق. وعلى سبيل المثال، نحن نشارك المعلومات بشكل علني لإظهار الاتجاهات الخاصة بالاستخدام
            العام لخدماتنا. ونسمح أيضًا لشركاء محددين بجمع المعلومات من المتصفح أو الجهاز الخاص بك لأغراض التسويق /
            العروض الترويجية وأغراض القياس باستخدام ملفات تعريف الارتباط الخاصة بهم أو التقنيات المماثلة.
          </p>
        </li>

        <li>
          <span class="note">العروض الترويجية</span>

          <p>
            نحن سنتبادل أو نكشف عن المعلومات الشخصية الخاصة بك خارج الشركة. على سبيل المثال، لتقديم خدمات متعلقة بالبريد
            الإلكتروني مثل النشرات الإخبارية التسويقية والعروض الترويجية والعروض.
          </p>
        </li>

        <li>
          <span class="note">نقل الأعمال</span>
          <p>
            نحن نقدم معلومات شخصية إلى الشركات التابعة لنا وغيرها من الشركات أو الأشخاص الموثوق بهم لمعالجتها من أجلنا ،
            بناءً على تعليماتنا ووفقًا لسياسة الخصوصية الخاصة بنا وأي تدابير أمنية وسرية أخرى مناسبة. فعلى سبيل المثال،
            نستخدم مزودي الخدمة لمساعدتنا في دعم العملاء.
          </p>
        </li>

        <li>
          <span class="note">الجانب القانوني</span>
          <p>
            في حالة وجود أي شرط بموجب أمر من المحكمة أو الحكومة أو جهة شبه حكومية للكشف عن معلوماتك الشخصية، فإننا سنكشف
            عن المعلومات التي قد تكون مطلوبة قانونًا. وقد نقوم أيضًا بالإفصاح عن معلوماتك الشخصية إذا اعتقدنا، بحسن نية،
            أن هذا الكشف ضروري للرد على مذكرات الاستدعاء أو أوامر المحكمة أو الإجراءات القانونية الأخرى.
          </p>

          <p>
            في حالة دمج الشركة أو الاستحواذ عليها من قبل شركة أخرى، يجوز لنا وللشركات التابعة لنا تبادل معلوماتك
            الشخصية، كليًا أو جزئيًا، مع كيان أعمال آخر.
          </p>
        </li>
      </ol>

      <!--  STORAGE AND SECURITY section -->
      <strong class="main-heading">6. التخزين والأمن</strong>

      <p>
        تكون الخدمة محمية ومشفرة لحماية خصوصيتك. إلا أننا قد ننقل البيانات التي نجمعها منك أو نخزنها في وجهة خارج منطقة
        الشرق الأوسط. كما يمكن أن تتم معالجة هذه البيانات على يد فريق العمل الذي يعمل لدينا أو لدى أحد موردينا خارج
        منطقة الشرق الأوسط. بتقديم بياناتك الشخصية تكون قد وافقت على عمليات النقل أو التخزين أو المعالجة. سنتخذ جميع
        الخطوات المعقولة اللازمة لضمان التعامل مع بياناتك بأمان وبما يتوافق مع سياسة الخصوصية الحالية. كما أنه لن يتم
        تخزين أو بيع أو مشاركة أو تأجير تفاصيل بطاقة الائتمان أو بطاقة السحب، أحدهما أو كليهما، الخاصة بك لأي طرف خارجي.
        فور استلامنا لمعلوماتك، سنتخذ أكثر التدابير الأمنية صرامة لمحاولة منع أي وصول غير مصرح به لهذه المعلومات.
      </p>

      <p>
        نحن ننفذ باستمرار إجراءات الأمن الإداري والفني والمادي ونحدّثها للمساعدة في حماية معلوماتك من الوصول أو الفقدان
        أو التلف أو التغيير غير المصرح به. وبعض الضمانات التي نستخدمها لحماية المعلومات الخاصة بك هي جدران الحماية
        وتشفير البيانات والضوابط الخاصة بالوصول إلى المعلومات.
      </p>

      <p>
        نحن نعمل على الصعيد العالمي، ويمكن نقل المعلومات الشخصية الخاصة بك إلى شركات تابعة للشركة أو أطراف أخرى في مواقع
        مختلفة في جميع أنحاء العالم من أجل الأغراض الموضحة في سياسة الخصوصية هذه. وأينما تم نقل معلوماتك الشخصية أو
        تخزينها أو معالجتها من قبلنا، سنتخذ خطوات معقولة لحماية خصوصية معلوماتك الشخصية.
      </p>

      <!-- Managing Your Information section -->
      <strong class="main-heading">7. إدارة المعلومات الخاصة بك</strong>

      <p>
        A. يمكنك الوصول إلى بعض معلوماتك الشخصية وتحديثها من خلال إعدادات حسابك. وإذا اخترت توصيل حساب شركتك بتطبيق خاص
        بطرف آخر مثل فيسبوك أو جوجل أو تويتر، يمكنك تغيير إعداداتك وإزالة إذن التطبيق عن طريق تغيير إعدادات حسابك. وأنت
        مسؤول عن تحديث معلوماتك الشخصية.
      </p>

      <p>
        B. يمكنك أن تطلب منا تصحيح المعلومات الشخصية غير الدقيقة أو غير المكتملة المتعلقة بك (وهي المعلومات التي لا
        يمكنك تحديثها بنفسك داخل حسابك لدى الشركة) عن طريق إرسال بريد إلكتروني إلينا.
      </p>

      <p>
        C. نحتفظ بمعلوماتك الشخصية طالما كان ذلك ضروريًا لتنفيذ العقد المبرم بينك وبيننا امتثالاً لالتزاماتنا القانونية.
        وإذا لم تعد تريد منا أن نستخدم المعلومات الخاصة بك، يمكنك أن تطلب منا محو المعلومات الشخصية الخاصة بك وإغلاق
        حسابك لدى الشركة عبر التواصل معنا عبر البريد التالي support@noorplay.com
      </p>

      <p>
        D. لسحب موافقتك في أي وقت خلال مدة استخدامك لخدماتنا، يمكنك اختيار القيام بذلك عن طريق إرسال بريد إلكتروني
        إلينا. وسنقوم بمراجعة طلبك وقد نطلب منك التحقق من هويتك. وبعد التحقق سنقوم بسحب الموافقة التي قدمت طلبًا من
        أجلها وإيقاف أي معالجة إضافية لمعلوماتك الشخصية.
      </p>

      <p>
        E. سنحتفظ ببياناتك طالما كان ذلك ضروريًا للرد على أية استفسارات أو شكاوى، أو لتحسين الخدمات التي نقدمها لك أو
        للامتثال لأية التزامات قانونية قد نخضع لها فقط. قد نحتفظ بالبيانات الشخصية بعد انتهاء علاقتنا التعاقدية معك
        للتعامل مع أية مشكلات بعد التعاقد (وفقًا لمدة التقادم المعمول بها) أو استفسارات المتابعة الخاصة بك.
      </p>

      <!--Children’s Privacy section-->
      <strong class="main-heading">8. خصوصية الأطفال</strong>

      <p>
        الشركة غير مصممة عن قصد أو موجهة للأشخاص الذين تقل أعمارهم عن 18 عام. ولا تسمح الشركة عن قصد لأي شخص يقل عمره عن
        18 عام بالتسجيل في الخدمات أو تقديم أي معلومات شخصية أخرى. فإننا نعتبر أنه من مسؤولية الآباء مراقبة استخدام
        أطفالهم لخدماتنا. ومع ذلك، فإن سياستنا هي عدم جمع ومعالجة أي معلومات شخصية من الأطفال دون سن 18 عامًا أو عرض
        إرسال أي مواد ترويجية إلى أشخاص من تلك الفئة. ولا تسعى الشركة أو تنوي طلب أو تلقي أي معلومات شخصية من الأطفال.
      </p>

      <!-- Respecting your contact preferences section -->
      <strong class="main-heading">9. احترام تفضيلات الاتصال الخاصة بك</strong>

      <p>
        تلتزم الشركة بالامتثال لأحكام عدم الاتصال. ولقد حرصنا دائمًا على مشاركة عملائنا بطريقة أكثر استهدافًا وملاءمة.
        تهدف رسائلنا التسويقية إلى إطلاعك على عروضنا الحصرية وبرنامج المكافآت والعروض الخاصة من شركائنا والمعلنين
        المفضلين لدينا.
      </p>

      <p>
        إذا كنت ترغب في مواصلة تلقي هذه الرسائل أو إلغاء الاشتراك، يمكنك بيان ما تفضله في أي وقت عبر إرسال بريد إلكتروني
        إلى فريق دعم العملاء لدينا.
      </p>

      <p>
        يرجى ملاحظة أنه بعد إلغاء الاشتراك، لا يزال بإمكانك الاستمرار في تلقي الرسائل غير التسويقية، مثل تحديثات المنتج
        وإشعارات الخدمة على النحو المسموح به بموجب القوانين واللوائح المحلية المعمول بها.
      </p>

      <!-- Change in Policy section -->
      <strong class="main-heading">10. التغيير في السياسة</strong>

      <p>
        تخضع سياسة الخصوصية هذه للتغيير من وقت لآخر ونحن نحتفظ بحقنا حسب تقديرنا لتعديل بنود سياسة الخصوصية هذه من وقت
        لآخر لضمان الامتثال للقوانين المعمول بها. وتسري الشروط المحدّثة فورًا وتحل محل هذه الشروط الخاصة بسياسة الخصوصية
        هذه. ولن نكون ملزمين بإعلامك بأي تغييرات تطرأ على سياسة الخصوصية هذه. أنت المسؤول الوحيد عن مراجعة سياسة
        الخصوصية من وقت لآخر لمعرفة أي تعديلات. ومن خلال الاستمرار في استخدام حساب الشركة بعد نشر الشروط المحدّثة، أنت
        تقر بموافقتك على الشروط المحدّثة.
      </p>

      <strong class="main-heading">11. كيفية الإتصال بنا</strong>

      <p>
        نرحب بأية أسئلة أو تعليقات أو طلبات تتعلق بسياسة الخصوصية هذه من خلال مراسلتنا عبر عنوان البريد الإلكتروني
        support@noorplay.com
      </p>

      <br />

      <p class="note center">
        تم تحديث / مراجعة سياسة الخصوصية هذه في 28 سبتمبر 2021 وتعتبر نافذة من تاريخ نشرها
      </p>
    </div>
  </div>
  <!-- <h2>سياسة الخصوصية</h2>
    <p>يشرح بيان الخصوصية هذا ممارساتنا ، بما في ذلك اختياراتك ، فيما يتعلق بجمع واستخدام وإفشاء بعض المعلومات ، بما في ذلك معلوماتك الشخصية ، والتي ستتم معالجتها بواسطتنا</p>
    <p>من خلال زيارة www.noorplay.com ("الخدمة") فإنك تقبل وتوافق على الممارسات الموضحة في هذه السياسة.</p>
    <h4>المعلومات التي قد نجمعها منك</h4>
    <p>قد نتلقى ونعالج المعلومات التالية عنك:</p>
    <ul>
        <li>المعلومات التي تصلنا منك: نقوم بجمع المعلومات التي تقدمها لنا والتي تشمل: 
            <ul>
                <li> الاسم، اسم العائلة ،العمر ،عنوان البريد الإلكتروني ،العنوان أو الرمز البريدي ، طريقة (طرق) الدفع ،رقم الهاتف. في بعض البلدان ، نجمع رقم تعريف حكومي لأغراض مثل الفواتير والامتثال الضريبي. نجمع هذه المعلومات بعدة طرق ، بما في ذلك عند إدخالها أثناء استخدام خدمتنا ، أو التفاعل مع خدمة العملاء لدينا ، أو المشاركة في الاستطلاعات أو العروض الترويجية التسويقية ؛ </li>
                <li> المعلومات التي تختارها عند التقييم أو تفضيلاتك من الخدمات الشخصية ، أو إعدادات الحساب (بما في ذلك التفضيلات المعينة في قسم "الحساب" على موقعنا على الويب) أو تقديم المعلومات إلينا من خلال خدماتنا أو في أي مكان آخر. </li>
            </ul>
        </li>
        <li>
            المعلومات التي نجمعها تلقائيًا: نقوم بجمع معلومات عنك وعن استخدامك لخدمتنا وتفاعلاتك معنا وإعلاناتنا ، وكذلك المعلومات المتعلقة بجهاز الكمبيوتر الخاص بك أو أي جهاز (أجهزة) أخرى تستخدم للوصول إلى خدماتنا (مثل أنظمة الألعاب ، أجهزة التلفاز الذكية والأجهزة المحمولة وأجهزة فك التشفير وأجهزة وسائط البث الأخرى). تتضمن هذه المعلومات: 
            <ul>
                <li> نشاطك على خدمة Noorplay ، مثل اختيار العناوين ، سجل المشاهدة واستعلامات البحث ؛ </li>
                <li> تفاعلاتك مع رسائل البريد الإلكتروني والرسائل النصية ، ورسائلنا من خلال الإشعارات والمسجات عبر الإنترنت ؛ </li>
                <li> تفاصيل تفاعلاتك مع خدمة العملاء لدينا ، مثل تاريخ ووقت وسبب الاتصال بنا ، ونصوص أي محادثات دردشة ، وإذا اتصلت بنا ، رقم هاتفك وتسجيلات المكالمات ؛ </li>
                <li>
                     معرفات الجهاز أو معرفات فريدة أخرى ؛ 
                </li>
                <li> معرفات الأجهزة القابلة لإعادة التعيين (المعروفة أيضًا باسم معرفات الإعلانات) ، مثل تلك الموجودة على الأجهزة المحمولة والأجهزة اللوحية وأجهزة الوسائط المتدفقة التي تتضمن هذه المعرفات ؛ </li>
                <li>
                     خصائص الجهاز والبرامج المشغلة (مثل النوع والتكوين) ، ومعلومات الاتصال ، والإحصائيات حول طرق عرض الصفحة ، ومصدر الإشارة (على سبيل المثال ، عناوين URL للإحالة) ، وعنوان IP (الذي قد يخبرنا بموقعك العام) ، والمتصفح ومعلومات سجل خادم الويب القياسية ؛ 
                </li>
                <li>
                     المعلومات التي يتم جمعها عن طريق استخدام ملفات الكوكيز الخاصة بالحفظ وإشارات الويب والتقنيات الأخرى ، بما في ذلك بيانات الإعلانات (مثل المعلومات المتعلقة بتوافر الإعلانات وتسليمها وعنوان URL للموقع وكذلك التاريخ والوقت). (راجع قسم "ملفات تعريف الارتباط والإعلانات عبر الإنترنت" للحصول على مزيد من التفاصيل.) 
                </li>
            </ul>
        </li>
        <li>
             المعلومات من الشركاء:نقوم بجمع المعلومات من الشركات الأخرى التي تربطك بها علاقة ("الشركاء"). قد يشمل هؤلاء الشركاء (اعتمادًا على الخدمات التي تستخدمها): مزود التلفزيون أو مزود خدمة الإنترنت الخاص بك ، أو مزودي خدمات وسائط البث الآخرين الذين يوفرون خدمتنا على أجهزتهم ؛ شركات الهاتف المحمول أو الشركات الأخرى التي تقدم خدمات لك وتحصيل المدفوعات مقابل الخدمة لتوزيعها علينا ؛ وموفري الأنظمة المساعدة الصوتية الذين يمكّنون التفاعل مع خدمتنا عبر الأوامر الصوتية. تختلف المعلومات التي يوفرها لنا الشركاء وفقًا لطبيعة خدمات الشركاء ، وقد تشمل: 
            <ul>
                <li> استعلامات البحث والأوامر المتعلقة بالوسائط التي تقوم بها من خلال الأجهزة الشريكة أو المنصات المساعدة الصوتية ؛ </li>
                <li> معلومات تنشيط الخدمة مثل عنوان بريدك الإلكتروني أو معلومات الاتصال الأخرى ؛ </li>
                <li> معرفات الجهاز أو غيرها من المعرفات الفريدة التي تدعم مصادقة المستخدم ، وتجربة تسجيل الخدمة ، ومعالجة دفع الشريك ، وتقديم محتوى Noorplay إليك من خلال أجزاء من واجهة مستخدم الشريك. </li>
            </ul>
        </li>
        <li>
             معلومات من مصادر أخرى:نحصل أيضًا على معلومات من مصادر أخرى. نحمي هذه المعلومات وفقًا للممارسات الموضحة في بيان الخصوصية هذا ، بالإضافة إلى أي قيود إضافية يفرضها مصدر البيانات. تختلف هذه المصادر بمرور الوقت ، ولكنها قد تشمل: 
             <ul>
                <li> مزودي الخدمة الذين يساعدوننا في تحديد الموقع بناءً على عنوان IP الخاص بك من أجل تخصيص خدماتنا وللاستخدامات الأخرى بما يتوافق مع بيان الخصوصية هذا ؛ </li>
                <li> مزودي خدمة الدفع الذين يزودوننا بمعلومات الدفع أو التحديثات لتلك المعلومات ، بناءً على علاقتهم معك ؛ </li>
                <li> مزودي البيانات عبر الاتصال أو عدم الاتصال بالإنترنت ، حيث نحصل على البيانات ذات الصلة بالكثافة السكانية، البيانات المرتبطة بالإعلانات القائمة على الاهتمامات. </li>
                <li> المصادر المتاحة للجمهور مثل قواعد البيانات الحكومية المفتوحة. </li>
             </ul>
        </li>
    </ul>
    <p>استخدام المعلومات</p>
    <p>نحن نستخدم البيانات لتوفير خدماتنا وجهودنا التسويقية وتحليلها وإدارتها وتحسينها وإضفاء طابع شخصي عليها ، ومعالجة تسجيلك وأوامرك ومدفوعاتك والتواصل معك بشأن هذه المواضيع وغيرها. على سبيل المثال ، نستخدم المعلومات من أجل:</p>
    <ul>
        <li> تحديد موقعك الجغرافي العام ، وتوفير المحتوى المترجم ، وتزويدك بتوصيات مشاهدة مخصصة ومخصصة للأفلام والبرامج التلفزيونية التي نعتقد أنها تهمك وبناء على اختياراتك السابقة ، وتحديد مزود خدمة الإنترنت الخاص بك ، ومساعدتنا في الاستجابة السريعة والفعالة للاستفسارات والطلبات ؛ </li>
        <li> التنسيق مع الشركاء حول إتاحة الخدمة للأعضاء وتوفير المعلومات لغير الأعضاء حول مدى توفر الخدمة ، بناءً على العلاقة المحددة التي تربطك بالشريك ؛ </li>
        <li> منع الأنشطة المحظورة أو غير القانونية المحتملة ، بما في ذلك الاحتيال ، وكشفها والتحقيق فيها وفرض شروطنا (مثل تحديد من هو مؤهل للفترة المجانية) </li>
        <li> تحليل وفهم جمهورنا ، وتحسين خدماتنا (بما في ذلك تجارب واجهة المستخدم الخاصة بنا) وتحسين اختيار المحتوى ، خوارزميات التوصية والتسليم ؛ </li>
        <li> التواصل معك بخصوص خدمتنا حتى نتمكن من إرسال أخبار حول Noorplay ، وتفاصيل حول الميزات الجديدة والمحتوى المتاح على Noorplay ، والعروض الخاصة ، والإعلانات الترويجية ، واستبيانات المستهلكين ، ولمساعدتك في الطلبات التشغيلية مثل طلبات إعادة تعيين كلمة المرور. قد تكون هذه الاتصالات بطرق مختلفة ، مثل البريد الإلكتروني والأشعارات والرسائل النصية وطرق المراسلة الأخرى عبر الإنترنت. </li>
    </ul>
    <p>الكشف عن معلوماتك</p>
    <p>قد نشارك معلوماتك مع أطراف ثالثة مختارة بما في ذلك:</p>
    <ul>
        <li>الشركاء في العمل والموردين وشركاء معتمدين آخرين لأداء أي عقد معهم أو معك ؛</li>
        <li>المعلنون وشبكات الإعلان التي تتطلب البيانات لتحديد الإعلانات ذات الصلة وتقديمها لك ولغيرك ؛ و / أو</li>
        <li>مقدمي التحليلات ومحركات البحث التي تساعدنا في تحسين الخدمة وتحسينها.</li>
    </ul>
    <p>قد نكشف معلوماتك الشخصية إلى أطراف ثالثة:</p>
    <ul>
        <li>في حالة قيامنا ببيع أو شراء أي عمل أو أصول ، وفي هذه الحالة قد نكشف بياناتك الشخصية إلى البائع أو المشتري المحتمل لهذه الأعمال أو الأصول ؛</li>
        <li>في هذه الحالة ، ستكون البيانات الشخصية التي تحتفظ بها حول عملائها أحد الأصول المنقولة ؛ و / أو</li>
        <li>إذا كان علينا واجب الكشف عن بياناتك الشخصية أو مشاركتها من أجل الامتثال لأي التزام قانوني ، أو من أجل إنفاذ أو تطبيق شروط الاستخدام و / أو الاتفاقيات الأخرى ؛ أو لحماية حقوق أو ملكية أو سلامة Noorplay أو عملائنا أو غيرهم.</li>
    </ul>
    <p>الكوكيز " ملفات تعريف الارتباط على شبكة الإنترنت أو سجل المتصفح"</p>
    <p>نحن نستخدم ملفات تعريف الارتباط لتمييزك عن المستخدمين الآخرين للخدمة. يساعدنا ذلك في تزويدك بتجربة جيدة عندما تتصفح الخدمة ويسمح لنا أيضًا بإجراء تحسينات. ملف تعريف الارتباط هو مستند نصي صغير جدًا ، غالبًا ما يحتوي على معرف فريد مجهول الهوية.</p>
    <p>يتم تخزين ملفات تعريف الارتباط على القرص الصلب ، وليس على الخدمة ، وبالتالي يمكنك ضبط متصفحك على رفض ملفات تعريف الارتباط و / أو حذفها. إذا قمت بذلك ، فستظل قادرًا على الوصول إلى الخدمة ، ولكن قد تجد أن بعض المناطق أو الصفحات لا تعمل بسلاسة كما قد تعمل مع تمكين ملفات تعريف الارتباط.</p>
    <p>سنستخدم ملفات تعريف الارتباط التالية:</p>
    <ul>
        <li>ملفات تعريف الارتباط الضرورية. هذه هي ملفات تعريف الارتباط اللازمة لتشغيل الخدمة. وهي تشمل ، على سبيل المثال ، ملفات تعريف الارتباط التي تمكنك من تسجيل الدخول إلى مواقع آمنة ؛</li>
        <li>ملفات تعريف الارتباط التحليلية / الأداء. هذه تتيح لنا التعرف على عدد زوار الخدمة وحسابها ، ومعرفة كيفية تحركهم حول الخدمة عند استخدامها. هذا يساعدنا على تحسين طريقة عمل الخدمة ؛</li>
        <li>ملفات تعريف الارتباط الوظيفية. هذه تستخدم للتعرف عليك عند العودة إلى الخدمة</li>
        <li>استهداف ملفات تعريف الارتباط. تسجل ملفات تعريف الارتباط هذه زيارتك للخدمة ، والصفحات التي قمت بزيارتها ، وصفحات المحتوى والمحتوى الذي شاهدته ، والروابط التي اتبعتها. سنستخدم هذه المعلومات لجعل الخدمة ، وإذا أمكن ، أي إعلان معروض عليها أكثر ملاءمة لاهتماماتك. يجوز لنا أيضًا مشاركة هذه المعلومات مع أطراف ثالثة لهذا الغرض.</li>
    </ul>
    <p>التخزين والأمن</p>
    <p>الخدمة آمنة ومشفرة لخصوصيتك. ومع ذلك ، قد يتم نقل البيانات التي نجمعها منك وتخزينها في وجهة خارج المنطقة. قد تتم معالجتها أيضًا بواسطة موظفين يعملون خارج المنطقة يعملون لحسابنا أو لأحد موردينا. من خلال تقديم بياناتك الشخصية ، فإنك توافق على هذا النقل أو التخزين أو المعالجة. سنتخذ جميع الخطوات اللازمة بشكل معقول لضمان التعامل مع بياناتك بشكل آمن ووفقًا لسياسة الخصوصية هذه. لن يتم تخزين أو بيع أو مشاركة أو تأجير أو تأجير بطاقة الائتمان و / أو تفاصيل بطاقة الائتمان لأي طرف ثالث.</p>
    <p>لسوء الحظ ، نقل المعلومات عبر الإنترنت غير آمن تمامًا. على الرغم من أننا سنبذل قصارى جهدنا لحماية بياناتك الشخصية ، إلا أنه لا يمكننا ضمان أمان بياناتك المنقولة إلى الخدمة ، وعلى هذا النحو فإن أي نقل يكون على مسؤوليتك الخاصة. بمجرد تلقينا معلوماتك ، سنستخدم إجراءات صارمة وميزات أمان لمحاولة منع الوصول غير المصرح به.</p>
    <p>مواقع الأطراف الثالثة</p>
    <p>قد تحتوي الخدمة ، من وقت لآخر ، على روابط من وإلى مواقع الأطراف الثالثة وشبكات شركائنا والمعلنين و / أو الشركات التابعة لنا. لا تشكل الروابط إلى أو من المواقع الخارجية التي لا نملكها أو نسيطر عليها موافقةً منا على المنتجات أو المعلومات الواردة فيها.</p>
    <p> إذا اتبعت رابطًا لأي من هذه المواقع الخارجية ، فالرجاء ملاحظة أنه سيكون لديهم سياسات الخصوصية الخاصة بهم والتي لا نقبل أي مسؤولية من طرفنا أو محاولة وضع مسؤولية عنها. يرجى التحقق من هذه السياسات قبل إرسال أي بيانات شخصية إلى تلك المواقع الخارجية. </p>
    <p>التغييرات في سياسة الخصوصية</p>
    <p>سيتم نشر أي تغييرات قد نجريها على سياسة الخصوصية في المستقبل على هذه الصفحة. يرجى معاودة التحقق باستمرار لمعرفة أي تحديثات أو تغييرات على سياسة الخصوصية الخاصة بنا.</p>
    <p>اتصل</p>
    <p>نرحب بالأسئلة والتعليقات والطلبات المتعلقة بسياسة الخصوصية هذه ويجب توجيهها إلى <a href="mailto:support@noorplay.com">support@noorplay.com</a></p>
  </div> -->
</template>

<script>
import { eventBus } from "@/eventBus";

export default {
  data() {
    return {
      localeLanguage: null,
    };
  },
  watch: {
    localeLanguage(val) {
      if (val === "eng") {
        this.$router.push("/en/PrivacyPolicy");
      } else if (val === "ara") {
        this.$router.push("/ar/PrivacyPolicy");
      }
    },
  },
  created() {
    window.scrollTo(0, 0);

    //checking for change of language in local storage value
    eventBus.$on("tc-pp", (data) => {
      this.localeLanguage = data;
    });
  },
};
</script>

<style lang="scss" scoped>
// .privacypolicy_ar {
//   background-color: #161616;
//   padding: 30px;
//   h2,
//   h4 {
//     color: #fff;
//     margin-top: 0;
//     font-family: Open Sans, sans-serif;
//   }
//   p {
//     margin: 15px 0;
//     font-family: Open Sans, sans-serif;
//     color: #fff;
//     font-size: 1rem;
//     letter-spacing: 0.15px;
//   }
//   ul {
//     padding: 10px 20px;
//     list-style-type: disc;
//     line-height: 20px;
//     color: #fff;
//     li {
//       padding: 10px 0;
//       list-style-type: circle;
//       line-height: 20px;
//     }
//   }
// }

.privacypolicy_ar {
  background-color: #161616;
  padding: 30px;
  h2 {
    color: #fff;
    margin-top: 0;
    font-size: 2rem;
    font-family: Open Sans, sans-serif;
  }
  .container_pp {
    font-family: Open Sans, sans-serif;
    color: #fff;
    font-size: 1rem;
    letter-spacing: 0.15px;

    .note {
      font-weight: bold;
    }

    .center {
      text-align: center;
    }

    .main-heading {
      font-size: 1.25rem;
    }

    p {
      margin: 15px 0;
      line-height: 23px;
    }

    ol {
      padding: 10px 20px;
      list-style-type: none;
      line-height: 20px;
      li {
        padding: 10px 0;
        list-style-type: none;
        line-height: 20px;
      }
    }
  }
}
</style>