<template>
  <div class="home">
    <menuTemplate :screens="screens" :menu="menu" :playerInstance="playerInstance"></menuTemplate>
    <router-view :playerInstance="playerInstance"></router-view>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { eventBus } from "@/eventBus";

export default {
  props: {
    menuDetail: {
      type: Object,
    },
    playerInstance: {
      type: Object,
    },
  },
  data() {
    return {
      menu: null,
      screens: null,
    };
  },
  computed: {
    ...mapGetters(["appConfig"]),
  },
  watch: {
    menuDetail(val) {
      if (val) {
        this.loadTemplate(val);
      }
    },
  },
  created() {
    // console.log("menu", this.menu);
   // this.loadInitialTemplate();
	 //decking approach
	  if (this.appConfig.featureEnabled && this.appConfig.featureEnabled.isDeckingEnabled) {
      this.loadInitTemplateDecking();
    } else {
      this.loadInitialTemplate();
    }
  },
  mounted() {
    // this.loadRoutedTemplate();
    // eventBus.$on("seeAll-from-home", this.loadSeeAllTemplate);
  },
  methods: {
    // function to load the template on initial app load.
    loadRoutedTemplate() {
      console.log("ROUTER PARAM LINK --- HOME", this.$route.params.link);
      if (this.$route.params.link) {
        this.loadTemplate(this.$route.params.link);
        this.menu = this.$route.params.link;
      }
    },
		//decking approach
		loadInitTemplateDecking () {
      let deckingconfig = localStorage.getItem("appDeckingConfig") ? JSON.parse(localStorage.getItem("appDeckingConfig")) : '';
      if (!this.appConfig) {
        this.loadTemplate(null);
      } else {
        // this.loadTemplate(this.appConfig.menu[0]);
        let profileDetails = localStorage.getItem("subscriberProfileDetails");
        if (profileDetails) {
          profileDetails = JSON.parse(profileDetails);
        }
        if (profileDetails && profileDetails.kidsmode === "YES") {
					let itemItem = "";
          deckingconfig.screens.forEach((menu) => {
            if (menu.profileTypes && menu.profileTypes.includes("KIDS")) {
              //this.loadTemplate(menu);
							itemItem = menu
            }
          });
					 this.screens = itemItem.sections;
            this.screens.sections = [];
            this.screens.sections = itemItem.sections;

            this.menu = itemItem;
        } else {
          let menuTemp = null;
					console.log("SCREENS-2",deckingconfig.screens)
          deckingconfig.screens.forEach((menu) => {
            if (menu.id.includes("Home") && menu.screenType === "HOME" && (menu.profileTypes.includes("ADULT") ||
              menu.profileTypes.includes("MASTER") || menu.profileTypes.includes("ALL"))) {
              menuTemp = menu;
            }
          });
					console.log("MENUTEMP", menuTemp)
          
          // this.loadTemplate(menuTemp);
						console.log("SCREENS-1",menuTemp.sections)
            this.screens = menuTemp.sections;
						this.screens.sections = [];
            this.screens.sections = menuTemp.sections;
            this.menu = menuTemp;
        }
      }
    },
    //load initial Template i.e home
    loadInitialTemplate() {
      if (!this.appConfig) {
        this.loadTemplate(null);
      } else {
        // this.loadTemplate(this.appConfig.menu[0]);
        let profileDetails = localStorage.getItem("subscriberProfileDetails");
        console.log("THE PROFILE profileDetails ", profileDetails);
        if (profileDetails) {
          profileDetails = JSON.parse(profileDetails);
        }
        if (profileDetails && profileDetails.kidsmode === "YES") {
          this.appConfig.menu.forEach((menu) => {
            if (menu.profileTypes && menu.profileTypes.includes("KIDS")) {
              this.loadTemplate(menu);
            }
          });
        } else {
          let menuTemp = null;

          this.appConfig.menu.forEach((menu) => {
            if (menu.id === "HOME") {
              menuTemp = menu;
            }
          });
          
          this.loadTemplate(menuTemp);

        }
      }
    },

    //function to load the home template screen with provided menu screens.
    loadTemplate(menu) {
      console.log("THE MENU I AM GETTING -> ",menu)
      if (menu) {
        this.menu = menu;

        let index = this.appConfig.screens.findIndex((element) => {
          return this.menu.id === element.id;
        });

        if (index > -1) {
          this.screens = this.appConfig.screens[index];
        }
      }
    },
  },
  components: {
    menuTemplate: () => import(/* webpackChunkName: "menuTemplate" */ "@/components/Templates/menuTemplate.vue"),
  },
};
</script>

<style lang="scss"></style>
