<template>
    <div class="termsofuse">
        <h2>Terms Of Use</h2>

        <div class="container_tc">
            <p>These terms and conditions outline the rules and regulations by visiting or browsing Noorplay's Website, located at www.noorplay.com and using the television, smart television, game console and/or set-top media applications, and/or mobile applications for mobile devices owned by us (“the service”).</p>
            <p>We assume you accept these terms and conditions. Do not continue to use Noorplay (“the service”) if you do not agree to take all of the terms and conditions stated on this page.</p>
            <p>
                The service is provided by Mithaq Media Production Limited, registered at the address 28 Devonshire Place, London, UK, registered with the company number 10998659.
            </p>

            <ol>
                <li>
                <strong>Membership</strong>
                <ol>
                    <li>your activity on the Noorplay service, such as title selections, watch history and search queries;</li>
                    <li>your interactions with our emails and texts, and with our messages through push and online messaging channels;</li>
                </ol>
                </li>
                <li>
                    <strong>Trial Membership</strong>
                    <ol>
                      <li>You may start your Noorplay membership with a free trial. The duration of the free trial period of your membership will be specified during sign-up and may differ from time to time.</li>
                      <li>Free trial eligibility is determined by Noorplay at its sole discretion and we may limit eligibility or duration to prevent free trial abuse. We reserve the right to revoke the free trial and put your account on hold in the event that we determine that you are not eligible. Members of households with an existing or recent Noorplay membership are not eligible. We may use information such as device ID, method of payment or an account email address used with an existing or recent Noorplay membership to determine eligibility. For combinations with other offers, restrictions may apply</li>
                      <li>We will charge the membership fee for the next billing cycle to your Payment Method at the end of the free trial period unless you cancel your membership prior to the end of the free trial period. To view the membership price and end date of your free trial period, visit our website and click the "Packages" link on the "Home" page</li>
                    </ol>
                </li>
                <li>
                  <strong>Billing and Cancellation</strong>
                  <ol>
                    <li>Billing Cycle. The membership fee for the service and any other charges you may incur in connection with your use of the service, such as taxes and possible transaction fees, will be charged to your Payment Method on the specific billing date indicated on your "Account" page. The length of your billing cycle will depend on the type of subscription that you choose when you sign-up for the service. In some cases, your payment date may change, for example, if your Payment Method has not successfully settled or if your paid membership began on a day not contained in a given month.</li>
                    <li>Price Changes. We reserve the right to adjust pricing for our service or any components thereof in any manner and at any time as we may determine in our sole and absolute discretion. Except as otherwise expressly provided for in these Terms of Use, any price changes to your service will take effect following notice to you by Noorplay</li>
                    <li>Cancellation. You can cancel your Noorplay membership at any time, and you will continue to have access to the Noorplay service through the end of your billing period. To the extent permitted by the applicable law, payments are non-refundable and we do not provide refunds or credits for any partial membership periods or unwatched Noorplay content. If you cancel your membership, your account will automatically close at the end of your current billing period. If you signed up for Noorplay using your account with a third party as a Payment Method and wish to cancel your Noorplay membership, you may need to do so through such third party, for example by visiting your account with the applicable third party and turning off auto-renew, or unsubscribing from the Noorplay service through that third party. You may also find billing information about your Noorplay membership by visiting your account with the applicable third party.</li>
                  </ol>
                </li>
                <li>
                  <strong>Noorplay Service</strong>
                  <ol>
                    <li>To make use of this service you need to be more than 18 years old or have received permission form your parents or the person who is authorized to pay your bill.</li>
                    <li>The service and any content viewed through the service are for your personal and non-commercial use only and may not be shared with individuals beyond your household. We grant you a limited, non-exclusive, non-transferable right to access the service and view Noorplay content. Except for the foregoing, no right, title or interest shall be transferred to you. You agree not to use the service for public performances.</li>
                    <li>You may view the Noorplay content primarily within the country in which you have established your account and only in geographic locations where we offer our service and have licensed such content. The content that may be available to watch will vary by geographic location and will change from time to time.</li>
                    <li>Some Noorplay content is available for temporary download and offline viewing on certain supported devices (“Offline Titles”). Limitations apply, how long the Offline Titles will remain accessible. Some Offline Titles may not be playable in certain countries and if you go online in a country where you would not be able to stream that Offline Title, the Offline Title will not be playable while you are in that country.</li>
                    <li>You agree to use the service, including all features and functionalities associated therewith, in accordance with all applicable laws, rules and regulations, or other restrictions on use of the service or content therein. You agree not to archive, reproduce, distribute, modify, display, perform, publish, license, create derivative works from, offer for sale, or use (except as explicitly authorized in these Terms of Use) content and information contained on or obtained from or through the service. You also agree not to: circumvent, remove, alter, deactivate, degrade or thwart any of the content protections in the service; use any robot, spider, scraper or other automated means to access the service; decompile, reverse engineer or disassemble any software or other products or processes accessible through the service; insert any code or product or manipulate the content of the service in any way; or use any data mining, data gathering or extraction method. In addition, you agree not to upload, post, e-mail or otherwise send or transmit any material designed to interrupt, destroy or limit the functionality of any computer software or hardware or telecommunications equipment associated with the service, including any software viruses or any other computer code, files or programs. We may terminate or restrict your use of our service if you violate these Terms of Use or are engaged in illegal or fraudulent use of the service.</li>
                    <li>The quality of the display of the Noorplay content may vary from device to device, and may be affected by a variety of factors, such as your location, the bandwidth available through and/or speed of your Internet connection. SD, HD, Ultra HD availability is subject to your Internet service and device capabilities. Not all content is available in all formats, such as HD, Ultra HD and not all subscription plans allow you to receive content in all formats. The minimum connection speed for SD quality is 0.5 Mbps; however, we recommend a faster connection for improved video quality. A download speed of at least 5.0 Mbps per stream is recommended to receive HD content (defined as a resolution of 720p or higher). A download speed of at least 25.0 Mbps per stream is recommended to receive Ultra HD (defined as a resolution of 1080p or higher). You are responsible for all Internet access charges. Please check with your Internet provider for information on possible Internet data usage charges. The time it takes to begin watching Noorplay content will vary based on a number of factors, including your location, available bandwidth at the time, the content you have selected and the configuration of your Noorplay ready device.</li>
                    <li>Noorplay software is developed by, or for, Noorplay and is designed to enable viewing of Noorplay content through Noorplay ready devices. This software may vary by device and medium, and functionalities and features may also differ between devices. You acknowledge that the use of the service may require third party software that is subject to third party licenses. You agree that you may automatically receive updated versions of the Noorplay and related third-party software.</li>
                  </ol>
                </li>
                <li>
                  <strong>Passwords and Account Access.</strong>
                  The member who created the Noorplay account and whose Payment Method is charged (the "Account Owner") has access and control over the Noorplay account and the Noorplay ready devices that are used to access our service and is responsible for any activity that occurs through the Noorplay account. To maintain control over the account and to prevent anyone from accessing the account, the Account Owner should maintain control over the Noorplay ready devices that are used to access the service and not reveal the password or details of the Payment Method associated with the account to anyone. You are responsible for updating and maintaining the accuracy of the information you provide to us relating to your account. We can terminate your account or place your account on hold in order to protect you, Noorplay or our partners from identity theft or other fraudulent activity. 
                </li>
                <li>
                  <strong>Warranties and Limitations on Liability.</strong>
                  The service is provided "as it is" and without warranty or condition. In particular, our service may not be uninterrupted or error-free. You waive all special, indirect and consequential damages against us. These terms will not limit any non-waivable warranties or consumer protection rights that you may be entitled to under the laws of your country of residence 
                </li>
                <li>
                  <strong>Miscellaneous</strong>
                  <ol>
                    <li>Governing Law. These Terms of Use shall be governed by and construed in accordance with the laws of the United Kingdom.</li>
                    <li>Survival. If any provision or provisions of these Terms of Use shall be held to be invalid, illegal, or unenforceable, the validity, legality and enforceability of the remaining provisions shall remain in full force and effect</li>
                    <li>Changes to Terms of Use and Assignment. Noorplay may, from time to time, change these Terms of Use. We will notify you at least 30 days before such changes apply to you. We may assign or transfer our agreement with you including our associated rights and obligations at any time and you agree to cooperate with us in connection with such an assignment or transfer.</li>
                    <li>Electronic Communications. We will send you information relating to your account (e.g. payment authorizations, invoices, changes in password or Payment Method, confirmation messages, notices) in electronic form only, for example via emails to your email address provided during registration</li>
                    <li>Customer Support. To find more information about our service and its features or if you need assistance with your account, please contact us via email at <a href="mailto:support@noorplay.com">support@noorplay.com</a></li>
                  </ol>
                </li>
            </ol>
        </div>
    </div>
</template>

<script>
import { eventBus } from "@/eventBus";

export default {
    data() {
        return{
          localeLanguage: null
        }
    },
    watch: {
      localeLanguage(val) {
        if (val === 'eng') {
            this.$router.push('/TermsOfUse')
        } else if (val === 'ara') {
            this.$router.push('/TermsOfUse-ar')
        }
      }
    },
    created() {
      window.scrollTo(0, 0);

      //checking for change of language in local storage value
      eventBus.$on("tc-pp", data => {
        this.localeLanguage = data;
      });
    }
}
</script>

<style lang='scss' scoped>
@import "@/sass/_variables.scss";
.termsofuse{
  background-color: #161616;
  padding: 30px;
  h2{
    color: #fff;
    margin-top: 0;
    font-family: Open Sans,sans-serif;
  }
  .container_tc{
    font-family: Open Sans,sans-serif;
    color: #fff;
    font-size: 1rem;
    letter-spacing: .15px;
    p{
      margin: 15px 0;
      line-height: 20px;
    }

    ol{
      padding: 10px 20px;
      // list-style-type: disc;
      line-height: 20px;
      li{
        padding: 10px 0;
        // list-style-type: circle;
        line-height: 20px;
      }
    };
  }
}
</style>